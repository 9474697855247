import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
	breakpoints: {
		ph: 767,
		ta: 979,
		de: 1439,
		hd: Infinity
	},
	defaultBreakpoint: 'ph' // customize this for SSR
})
