<template>
	<div class="notifications" v-show="notifications.length > 0">
		<div
			class="notification"
			@click="removeNotification(notification.text)"
			:class="notification.type"
			v-for="notification in notifications"
			:key="notification.text"
		>
			<i
				v-if="notification.type === 'error'"
				class="ico ico-exclamation-circle"
			></i>
			<i
				v-if="notification.type === 'success'"
				class="ico ico-check-circle"
			></i>
			<p>{{ notification.text }}</p>
			<i class="ico ico-close"></i>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	methods: {
		removeNotification(text) {
			this.$store.dispatch('removeNotification', text)
		}
	},

	computed: {
		...mapGetters(['notifications'])
	}
}
</script>

<style lang="scss" scoped>
@import 'Notifications';
</style>
