import Vue from 'vue'

Vue.directive('track', {
	bind(el, binding, vnode) {
		if (!binding.arg) {
			throw new Error(
				'An action/event listener must be provided like so "v-track:click=\'{}\'"'
			)
		}

		el.addEventListener(binding.arg, () => {
			// console.log('triggered', binding.arg, binding.value, el, binding, vnode)
			vnode.context.$ga.event({
				eventCategory: binding.value.category || '',
				eventAction: binding.value.action || binding.arg || '',
				eventLabel: binding.value.label || '',
				eventValue: binding.value.value || 0
			})
		})
	}
})
